/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h3: "h3",
    a: "a",
    div: "div",
    h4: "h4",
    p: "p",
    strong: "strong",
    h2: "h2"
  }, _provideComponents(), props.components), {Hero, Columns, Column, Background, List, Video, Testimonial, Cta} = _components;
  if (!Background) _missingMdxReference("Background", true);
  if (!Column) _missingMdxReference("Column", true);
  if (!Columns) _missingMdxReference("Columns", true);
  if (!Cta) _missingMdxReference("Cta", true);
  if (!Hero) _missingMdxReference("Hero", true);
  if (!List) _missingMdxReference("List", true);
  if (!Testimonial) _missingMdxReference("Testimonial", true);
  if (!Video) _missingMdxReference("Video", true);
  return React.createElement(React.Fragment, null, React.createElement(Hero, {
    headline: "Customer Story: Raven Industries",
    text: "How Raven used Prismatic to quickly deliver the integrations needed to land larger customers and reduce dev time per integration by 80%.",
    backgroundImage: "primary",
    background: "deepPurple",
    color: "white01",
    size: "xsmall"
  }), "\n", React.createElement(Columns, null, React.createElement(Column, {
    md: 4
  }, React.createElement(Background, {
    background: "gray03",
    color: "deepPurple",
    fullWidth: false
  }, React.createElement(_components.h3, {
    id: "key-results",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#key-results",
    "aria-label": "key results permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Key Results"), React.createElement(_components.h4, {
    id: "80-less-dev-time-per-integration",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#80-less-dev-time-per-integration",
    "aria-label": "80 less dev time per integration permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "80% less dev time per integration"), React.createElement(_components.h4, {
    id: "outsourced-integration-dev-to-contract-team",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#outsourced-integration-dev-to-contract-team",
    "aria-label": "outsourced integration dev to contract team permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Outsourced integration dev to contract team"), React.createElement(_components.h4, {
    id: "landed-larger-clients-with-enterprise-level-integration-needs",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#landed-larger-clients-with-enterprise-level-integration-needs",
    "aria-label": "landed larger clients with enterprise level integration needs permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Landed larger clients with enterprise-level integration needs")), React.createElement(Background, {
    background: "gray03",
    color: "deepPurple",
    fullWidth: false
  }, React.createElement(_components.h3, {
    id: "about-raven",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#about-raven",
    "aria-label": "about raven permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "About Raven"), React.createElement(_components.p, null, "Raven Industries is a precision agricultural solutions provider dedicated to advancing farming through technology. Its innovative products and systems maximize operational efficiencies for growers and unlock new possibilities in farming."), React.createElement(_components.p, null, React.createElement(_components.strong, null, "Industry:"), " Agriculture"), React.createElement(_components.p, null, React.createElement(_components.strong, null, "Size:"), " 900 employees"), React.createElement(_components.p, null, React.createElement(_components.strong, null, "Website:"), " ", React.createElement(_components.a, {
    href: "https://ravenind.com/",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "ravenind.com"))), React.createElement(Background, {
    background: "gray03",
    color: "deepPurple",
    fullWidth: false
  }, React.createElement(_components.h3, {
    id: "why-prismatic",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#why-prismatic",
    "aria-label": "why prismatic permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Why Prismatic"), React.createElement(List, {
    variant: "check"
  }, React.createElement("li", null, "Built specifically for B2B SaaS companies"), React.createElement("li", null, "Modern, scalable technology"), React.createElement("li", null, "Gives support & sales teams visibility into integrations")))), React.createElement(Column, {
    md: 8
  }, React.createElement(Video, {
    image: "/images/customers/chris-rallis-thumbnail.png",
    videoId: "821697845"
  }), React.createElement(_components.h2, {
    id: "challenge",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#challenge",
    "aria-label": "challenge permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Challenge"), React.createElement(_components.p, null, "Raven Industries had recently acquired an agricultural logistics platform with the goal of scaling it up to land larger customers. First, they needed to enable enterprise-level workflows. For Director of Software Chris Rallis and his team, that meant building integrations to numerous agricultural ERPs and other business systems."), React.createElement(_components.p, null, "Progress on the integrations was slow."), React.createElement(Testimonial, {
    id: "raven-1",
    color: "deepPurple"
  }), React.createElement(_components.p, null, "After building each integration, they then needed to customize it for each additional customer. They didn't have a good framework for handling that."), React.createElement(_components.p, null, "\"It wasn't exciting work for engineers to be working on, and it also wasn't moving our product forward from a competitive perspective, on what it could do from a differentiating factor,\" Chris said."), React.createElement(_components.p, null, "Before long, the dev teams on that product were spending around 70% of their capacity on integrations. They needed a way to go faster."), React.createElement(_components.h2, {
    id: "solution",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#solution",
    "aria-label": "solution permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Solution"), React.createElement(_components.p, null, "Around that time, Chris came across Prismatic's embedded integration platform (embedded iPaaS)."), React.createElement(_components.p, null, "He hadn't seriously considered an integration platform because other solutions weren't built for his team's use case."), React.createElement(_components.p, null, "\"A lot of the integration platforms that we looked at were designed for IT departments to connect business systems together internally and maybe made the leap into SaaS connections for customers or end users,\" Chris said. \"Prismatic doesn't have that weight of that migration. It's for B2B SaaS companies.\""), React.createElement(_components.p, null, "Chris liked how Prismatic treated end users as a first-class concept, something he noticed wasn't true of many integration platforms. This allowed for smart handling of customer-specific configurations and credentials."), React.createElement(Testimonial, {
    id: "raven-2",
    color: "deepPurple"
  }), React.createElement(_components.h2, {
    id: "results",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#results",
    "aria-label": "results permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Results"), React.createElement(_components.p, null, "After adopting Prismatic, Raven delivered integrations faster, won many of the larger customers they were targeting, and increased usage-driven revenue."), React.createElement(_components.p, null, "\"We're able to deliver a solution that is a premium product, reduces frictionful business workflows, and the quality of the integrations is second to none,\" said Chris."), React.createElement(_components.p, null, "As Chris anticipated, Prismatic has allowed the Raven team to build integrations in a way that's scalable and sustainable from a technical debt perspective. They now spend 80% less dev time building each integration."), React.createElement(_components.p, null, "Prismatic also enabled Raven to bring on outsourced contractor teams to build integrations. The core engineering team, who understands Raven's domain, can now focus on building their next generation platform."), React.createElement(_components.p, null, "Support can now dig into integration issues on their own, and they've removed a full-time engineer from integration support. Team members who are on site with customers have an easy way to customize workflows. Sales now has visibility into which integrations are available."), React.createElement(Testimonial, {
    id: "raven-3",
    color: "deepPurple"
  }), React.createElement(_components.p, null, "Chris offered this advice for other software leaders: \"I'd recommend Prismatic to anyone delivering B2B software that requires integrations to win more customers.\""), React.createElement(Cta, {
    title: "Inspired by what Raven achieved?",
    copy: "Get a tailored demo to see how Prismatic can help your team deliver powerful native integrations, fast.",
    label: "Book a Demo",
    to: "https://prismatic.io/request-a-demo/"
  }))));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
